@import url("https://fonts.googleapis.com/css?family=Fauna One");
@import url("https://fonts.googleapis.com/css?family=Cinzel");
/* 
.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */


/* }  */

/* .react-player {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;


} */

.doTitle {
  font-family: "Helvetica", sans-serif !important;
  color: black !important
}

.doText {
  font-family: "Helvetica", sans-serif !important;
  color: black !important
}

.doContainer {

  background: white;
  border-radius:15px !important;
  color: black !important;

}

.doInnerContainer {
  background: #F8F9FA !important;
  border-radius:15px;
}

.loveContainer {
  background: #c8d8e4 !important;
  border-radius:15px !important;

}

.loveInnerContainer {
  background: #2b6777 !important;
 padding:30px!important;
 border-radius:15px;
  

}

.loveTitle {
  font-family: "Cinzel" !important;
  color: white !important;
  margin-bottom:13.9px !important;
 
}

.loveText {
  font-family: "Fauna One", sans-serif !important;
  color: white !important;

}

.pSeperator {
  margin-bottom: 10px;
}

.discordContainer {
  border-radius:15px !important;
  background: #009688 !important;


}

.discordInnerContainer {
  background: white !important;
  padding: 14.5px !important;
  border-radius:15px;

}

.discordTitle {
  font-family: "Open sans, sans-serif" !important;
  color: black !important;
  margin-bottom: 11px!important;
  
}

.discordInfo {
  font-family: "Open sans, sans-serif" !important;
  color: rgb(110, 107, 107) !important;
}

.discordCardIcons {
  margin-top: 5px;
  margin-bottom: 5px;
}

.socialCardIcons {
  margin-top: 5px;
  margin-bottom: 5px;
}

.cardDivider {
  margin-top: 35px;


}

.centerRow {

  justify-content: center !important;
  align-items: stretch !important;
}

.linkIcon {
  margin-left: 15px;
  width: 25px;
  height: 25px;
}

.gitIcon {
  width: 25px;
  height: 25px;
}

