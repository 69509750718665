.contactText{
    text-align: center;
    
}
.contactText2{
    margin-top:10px
}

.contactButton{
    margin-top: 50px;
    text-align: center;
    
}