

.github{
margin-left:15px;

}
.email{
    margin-left:15px;
   
}
@media (max-width: 480px) { 
.githubIcon{
   
    width:15px!important;
    height:15px!important;
    
   

} .linkedInIcon{
    width:15px!important;
    height:15px!important;
    
  
}}
.linkedInIcon{
    width:25px;
    height:25px;
   
}
.githubIcon{
    
    width:25px;
    height:25px;
   
   

}
.emailIcon{
   
    width:25px;
    height:25px;
   
}

.MuiLink-underlineNone:hover{
    color:rgba(115,199,204,1);
    text-decoration:none;
}