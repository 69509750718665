@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&family=Roboto+Slab:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap');


.appContainer {
  background: #26418f;

  color: black;
}


.home {

  position: relative;
  top: 20.5vw;

}

.builtSeperator {
  margin-top: 17vw;
}

.about {
  margin-top: 35vw;
  padding-top: 107px !important;
}

.sectionSeperator {
  margin-top: 20vw;
  padding-top: 107px !important;

}



@media (max-width: 480px) {
  .home {

    position: relative;
    top: 80vw !important;

  }

  .builtSeperator {
    margin-top: 60vw !important;
  }

  .about {
    margin-top: 200vw;
    padding-top: 107px !important;

  }

  .sectionSeperator {
    margin-top: 70vw !important;
    padding-top: 107px !important;

  }
}

/* ipad Pro */





@media (max-width: 768px) and (min-width:481px) {
  .home {

    position: relative;
    top: 60vw !important;

  }

  .builtSeperator {
    margin-top: 35vw !important;
  }

  .about {
    margin-top: 100vw;
    padding-top: 107px !important;

  }

  .sectionSeperator {
    margin-top: 40vw !important;
    padding-top: 107px !important;

  }
}



@media (min-width: 768px) and (max-width: 1024px) {
  .home {

    position: relative;
    top: 40vw !important;

  }

  .builtSeperator {
    margin-top: 25vw !important;
  }

  .about {
    margin-top: 90vw;
    padding-top: 107px !important;

  }

  .sectionSeperator {
    margin-top: 25vw !important;
    padding-top: 107px !important;

  }
}

