/* .accordionTitle{
    font-family:'Pacifico', cursive!important;
    color:white!important;
}


.accordionText{
    font-family:'Pacifico', cursive!important;
    color:#015249!important;
    
} */
.MuiLink-underlineNone:hover {
    color: rgba(115, 199, 204, 1);
    text-decoration: none;
}



.accordionItems {
    margin-left: 21px
}