@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300&family=Roboto+Slab:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Fauna One);
@import url(https://fonts.googleapis.com/css?family=Cinzel);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.appContainer {
  background: #26418f;

  color: black;
}


.home {

  position: relative;
  top: 20.5vw;

}

.builtSeperator {
  margin-top: 17vw;
}

.about {
  margin-top: 35vw;
  padding-top: 107px !important;
}

.sectionSeperator {
  margin-top: 20vw;
  padding-top: 107px !important;

}



@media (max-width: 480px) {
  .home {

    position: relative;
    top: 80vw !important;

  }

  .builtSeperator {
    margin-top: 60vw !important;
  }

  .about {
    margin-top: 200vw;
    padding-top: 107px !important;

  }

  .sectionSeperator {
    margin-top: 70vw !important;
    padding-top: 107px !important;

  }
}

/* ipad Pro */





@media (max-width: 768px) and (min-width:481px) {
  .home {

    position: relative;
    top: 60vw !important;

  }

  .builtSeperator {
    margin-top: 35vw !important;
  }

  .about {
    margin-top: 100vw;
    padding-top: 107px !important;

  }

  .sectionSeperator {
    margin-top: 40vw !important;
    padding-top: 107px !important;

  }
}



@media (min-width: 768px) and (max-width: 1024px) {
  .home {

    position: relative;
    top: 40vw !important;

  }

  .builtSeperator {
    margin-top: 25vw !important;
  }

  .about {
    margin-top: 90vw;
    padding-top: 107px !important;

  }

  .sectionSeperator {
    margin-top: 25vw !important;
    padding-top: 107px !important;

  }
}


.navBar {
    background-color: #26418f;

    opacity: 0.95;



    /* box-shadow: 10px 10px 5px grey; */
}

.brandIcon {
    opacity: .5 !important
}

.navText {
    color: #e0f7fa !important;
    font-family: 'Oswald', sans-serif;
    opacity: 1.0 !important;


}

.collapseIcon {
    height: 39px;
    width: 39px;
}

.navText:hover {
    color: rgba(115, 199, 204, 1) !important;
}

.navbar-brand {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;

}

.resumeNav {}

.underline {
    position: relative;
    height: 2px;
}



.navbar-toggler.collapsed {
    border: none;
    outline: none !important;
    box-shadow: none !important;
}

.navbar-toggler {
    border-color: rgba(115, 199, 204, 1) !important;
    border: none;
    outline: none !important;
    box-shadow: none !important;

}

.navbar-toggler-icon {
    color: rgba(115, 199, 204, 1);
    border: none;
    outline: none !important;
    box-shadow: none !important;
}

.hamIcon {
    color: rgba(115, 199, 204, );
    background-color: rgba(115, 199, 204, )
}
.introductionText {
    font-family: 'Pacifico', cursive;
    color: #015249 !important;
    font-size: 25px;

}

.nameText {
    margin-top: 10px !important;
    font-family: 'Merriweather', serif;
    color: white;
    font-size: 70px;

}

.whoText {
    margin-top: 10px !important;

}

@media (max-width: 480px) {
    .profPicContainer {
        position: relative;
        bottom: 6px !important;

    }
}


.profPicContainer {
    position: relative;
    bottom: 6vw;

}

.profPic {
    border-radius: 5px;


}

.socialButtons {
    margin-top: 13px;
}

/* .introductionText{
    font-family:'Pacifico', cursive;
    color:#015249!important;
    font-size:1.6vw;
    
}
.nameText{
    font-family: 'Merriweather', serif;
    color:white;
    font-size:4.4vw;
    
}

.whoText{
    font-family: 'Merriweather', serif;
    color:white;
    font-size:1.6vw;
    
} */
.aboutTitleText {
  font-family: 'Merriweather', serif;
  color: white;
  font-size: 35px;

}


.aboutText {
  margin-bottom: 15px
}

.accordion {
  margin-top: 50px;

}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 50px;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1 1;
  border-bottom: .1px solid #e0f7fa;
  opacity: .2
}

.separator:not(:empty)::before {
  margin-right: 1.0em;
}

.separator:not(:empty)::after {
  margin-left: 1.0em;
}
/* 
.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */


/* }  */

/* .react-player {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;


} */

.doTitle {
  font-family: "Helvetica", sans-serif !important;
  color: black !important
}

.doText {
  font-family: "Helvetica", sans-serif !important;
  color: black !important
}

.doContainer {

  background: white;
  border-radius:15px !important;
  color: black !important;

}

.doInnerContainer {
  background: #F8F9FA !important;
  border-radius:15px;
}

.loveContainer {
  background: #c8d8e4 !important;
  border-radius:15px !important;

}

.loveInnerContainer {
  background: #2b6777 !important;
 padding:30px!important;
 border-radius:15px;
  

}

.loveTitle {
  font-family: "Cinzel" !important;
  color: white !important;
  margin-bottom:13.9px !important;
 
}

.loveText {
  font-family: "Fauna One", sans-serif !important;
  color: white !important;

}

.pSeperator {
  margin-bottom: 10px;
}

.discordContainer {
  border-radius:15px !important;
  background: #009688 !important;


}

.discordInnerContainer {
  background: white !important;
  padding: 14.5px !important;
  border-radius:15px;

}

.discordTitle {
  font-family: "Open sans, sans-serif" !important;
  color: black !important;
  margin-bottom: 11px!important;
  
}

.discordInfo {
  font-family: "Open sans, sans-serif" !important;
  color: rgb(110, 107, 107) !important;
}

.discordCardIcons {
  margin-top: 5px;
  margin-bottom: 5px;
}

.socialCardIcons {
  margin-top: 5px;
  margin-bottom: 5px;
}

.cardDivider {
  margin-top: 35px;


}

.centerRow {

  justify-content: center !important;
  align-items: stretch !important;
}

.linkIcon {
  margin-left: 15px;
  width: 25px;
  height: 25px;
}

.gitIcon {
  width: 25px;
  height: 25px;
}




.github{
margin-left:15px;

}
.email{
    margin-left:15px;
   
}
@media (max-width: 480px) { 
.githubIcon{
   
    width:15px!important;
    height:15px!important;
    
   

} .linkedInIcon{
    width:15px!important;
    height:15px!important;
    
  
}}
.linkedInIcon{
    width:25px;
    height:25px;
   
}
.githubIcon{
    
    width:25px;
    height:25px;
   
   

}
.emailIcon{
   
    width:25px;
    height:25px;
   
}

.MuiLink-underlineNone:hover{
    color:rgba(115,199,204,1);
    text-decoration:none;
}
/* .accordionTitle{
    font-family:'Pacifico', cursive!important;
    color:white!important;
}


.accordionText{
    font-family:'Pacifico', cursive!important;
    color:#015249!important;
    
} */
.MuiLink-underlineNone:hover {
    color: rgba(115, 199, 204, 1);
    text-decoration: none;
}



.accordionItems {
    margin-left: 21px
}
.contactText{
    text-align: center;
    
}
.contactText2{
    margin-top:10px
}

.contactButton{
    margin-top: 50px;
    text-align: center;
    
}
.builtText{
    text-align: center;
    position: relative;
    bottom: 5px!important;
}
